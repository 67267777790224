<template>
  <div class="notice">
    <cms-header></cms-header>
    <!--面包屑-->
    <div class="width_width child_bg_zx">
      <div class="width_w clearfix">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>
          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link to="/information">
            <a class="now_a left" href="javascript:void(0)">资讯中心</a>
          </router-link>
        </div>
      </div>
      <span class="child_name">资讯中心</span>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left">
        <ul class="tab_con clearfix">
          <li v-for="(item,index) in informationTypeList" :key="'information-'+index">
            <a :class="activeClass == index ? 'tab_active':''" @click="getItem(index,item,'activeClass')" class="tab_li"
               href="javascript:void(0)">{{ item.categoryTitle }}</a>
          </li>
          <form class="menu_form right clearfix" style="margin-top: 13px;margin-right: 10px;">
            <input class="menu_search left" v-model="searchVal" type="text" placeholder="请输入文章标题"
                   @blur="changeSearchVal(searchVal)"/>
            <input class="menu_s_b left " type="button" @click="getItems(searchVal)"/>
          </form>
        </ul>
        <div class="tender_con">
          <a @click="contentDetails(item)" class="news_a" href="javaScript:void(0)" v-for="(item,index) in contentList"
             :key="'contentList'+index">
            <span class="news_one">{{ item.contentTitle }}</span>
            <div class="news_con">
              <img v-if="item.contentCategoryId ==170" :src="item.contentImg[0].url"
                   class="news_pic cms-sm-hidden cms-ssm-hidden"/>
              <div class="news_box">
                <span v-if="item.contentCategoryId ==170" class="news_span">{{ item.contentDescription }}</span>
                <div class="clearfix"><span class="news_time left">{{ item.contentDatetime }}</span>
                  <span v-if="item.contentRelease" class="news_time left news_company"
                        style="text-align: right">{{ item.contentRelease }}</span>
                  <span class="news_see right cms-sm-hidden cms-ssm-hidden">查看详情</span></div>
              </div>
            </div>
          </a>
          <div class="cms-box ">
            <el-pagination
                :page-size="pageSize"
                :total="total"
                :pager-count="5"
                @current-change="handleCurrentChange"
                background
                :current-page="currentPage"
                layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="notice-right right cms-sm-hidden cms-ssm-hidden cms-md-hidden">
        <div class="child_tool">
          <!--工具栏-->
          <toolbar></toolbar>
          <!--广告-->
          <advertising></advertising>
          <!--联系卡片-->
          <contact-card></contact-card>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import Toolbar from "../../components/commoon/component/toolbar";
import ContactCard from "../../components/commoon/component/contact-card";
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";
import Advertising from "../../components/commoon/component/advertising";

export default {
  name: "information",
  components: {
    Advertising,
    CmsFoot,
    CmsHeader,
    ContactCard,
    Toolbar
  },
  data() {
    return {
      informationTypeList: [],
      searchVal: '',
      contentList: [],
      activeClass: 0,
      query: {},
      activeClass1: 0,
      activeClass2: 0,
      total: 0,
      currentPage: 0,
      pageSize: 6,
    }
  }, methods: {
    getParameter: function (paraName) {
      var url = document.location.toString();
      //去除路由的#号
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    selectContentList: function (modelId, name) {
      var that = this;
      that.$http.get(that.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
        that.query.contentCategoryId = data.data.result[that.activeClass].id;
        if (that.getParameter("index") == 1) {
          that.getInnerBulletinList();
        } else {
          that.getContentList();
        }
      }).catch(function (error) {
        console.log(error);
      })
    },
    getContentList: function () {
      var that = this;
      that.$http.post(that.base + "/content/page.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsContentDTO: that.query
      }).then(function (data) {
        for (var i = 0; i < data.data.result.records.length; i++) {
          if (data.data.result.records[i].contentImg != null && data.data.result.records[i].contentImg != "") {
            data.data.result.records[i].contentImg = JSON.parse(data.data.result.records[i].contentImg);
          }
        }
        that.contentList = data.data.result.records;
        that.total = data.data.result.total;
      }).catch(function (error) {
        console.log(error)
      })
    },
    getItem: function (index, item, activeClassName, searchVal) {
      this.currentPage = 0;
      var that = this;
      console.log(that)
      this.query.contentCategoryId = item.id;
      this[activeClassName] = index;
      if (index != '1') {
        this.getContentList();
      } else {
        this.getInnerBulletinList();
      }
    },
    getInnerBulletinList: function () {
      var that = this;
      that.$http.post(that.base + "/innerBulletin/getInnerBulletinList.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsContentDTO: that.query
      }).then(function (data) {
        let myData = data.data.result.records;
        that.contentList = myData;
        for (var i = 0; i < myData.length; i++) {
          that.contentList[i].contentTitle = myData[i].theme;
          that.contentList[i].contentDatetime = myData[i].publicityBeginTime + " ~ " + myData[i].publicityEndTime;
        }
        that.total = data.data.result.total;
      }).catch(function (error) {
        console.log(error)
      })
    },
    changeSearchVal: function (searchVal) {
      var that = this;
      if (searchVal) {
        that.query.searchVal = "";
      } else {
        that.query.searchVal = searchVal;
      }
    },
    getItems: function (searchVal) {
      this.currentPage = 0;
      /*文章搜索*/
      var that = this;
      that.query.searchVal = searchVal;
      this.getContentList();
    },
    handleCurrentChange: function (val) {
      this.currentPage = val;
      this.getContentList(this.query);
    },
    /*文章详情*/
    contentDetails: function (item) {
      let json = "";
      if (item.theme == null || item.theme == '') {
        json = {'path': '/informationDetails', 'query': {'id': item.id}};
      } else {
        json = {'path': '/informationDetails', 'query': {'id': item.id, 'type': '1'}};
      }
      this.$router.push(json);
    },
  }, created() {
    this.activeClass = this.getParameter("index");
    if (this.activeClass == null || this.activeClass == "") {
      this.activeClass = 0;
    }
    /*初始化推广列表*/
    this.selectContentList("information", "informationTypeList")
    this.getContentList();
    document.title = this.title;

  }
}
</script>
<style>
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
<style scoped>
.news_box {
  width: 100%;
}

.news_time {
  width: 40%;
  text-align: left;
}

@media screen  and (max-width: 768px) {
  .news_time {
    width: 50%;
    text-align: left !important;
  }

  .news_company {
    text-align: right !important;
  }
}
</style>
